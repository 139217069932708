import React from "react"
import PolicyLayout from "../../components/policy-layout"
import ArrowIcon from "../../images/arrow3.svg"
import "./styles.css"
import { openHubspotChat } from "../../utils"

const ContactsPage = () => {
  return (
    <PolicyLayout>
      {({ toggle }) => (
        <div className="privacy-page">
          <div className="wt-flex" onClick={toggle}>
            <h1>Contact</h1>
            <img
              src={ArrowIcon}
              alt="arrow"
              className="wt-margin-left-10 display-lg-none display-none"
            />
          </div>

          <div className="contacts-page__info">
            <div className="contacts-page__address">
              <p>
                WebTotem AI <br />
                Headquarters in <br />
                Poland, Warsawa
              </p>
            </div>
          </div>

          <h1 className="wt-text wt-text--blue wt-margin-bottom-30">
            How to reach us
          </h1>
          <p>
            There are multiple ways to reach members of the WebTotem team and
            the WebTotem community depending on what you need some help with.
            Here's a list of the best ways to get in touch:
          </p>
          <p>
            • For support using WebTotem, the fastest way to get help is on our{" "}
            <a href="#" onClick={openHubspotChat}>
              live chat
            </a>
            <br />• WebTotem(Pro) customers can always reach us on{" "}
            <a href="mailto:support@wtotem.com">support@wtotem.com</a>
            <br />• To report security issues or vulnerabilities, follow to{" "}
            <a href="mailto:support@wtotem.com">support@wtotem.com</a>
            <br />• For another question follow to{" "}
            <a href="mailto:support@wtotem.com">support@wtotem.com</a>
          </p>

          <p>Thanks for stopping by!</p>
        </div>
      )}
    </PolicyLayout>
  )
}

export default ContactsPage
